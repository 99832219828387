<template>
    <div class="rounded-5 w-100 my-5">
        <div class="d-flex justify-content-between">
            <div class="bg-white rounded-4 border mx-2 widget-list">
                <h1
                    class="px-3 py-3 page-heading"
                    style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0; margin-bottom: 0"
                >
                    Selected Modules widgets
                </h1>
                <draggable
                    class="draggable-list d-grid widgets-list w-90 p-4"
                    style="
                        grid-template-columns: repeat(2, 1fr);
                        gap: 3%;
                        overflow: auto;
                        height: 426px;
                        align-items: center;
                    "
                    :list="widgetsList"
                    :group="{ name: 'myGroup', pull: true, put: false }"
                    @start="onDragStart"
                    @end="onDragEnd"
                >
                    <div
                        v-for="(element, index) in widgetsList"
                        :key="index"
                        style="margin-left: auto; margin-right: auto"
                    >
                        <div v-if="element.function_type === 'generic'" class="generic-widget">
                            <img :src="element.image" alt="" style="margin: auto" />
                        </div>
                        <img
                            v-else-if="element.function_type === 'core' && element.function_name == 'RSS FEED'"
                            :src="element.image"
                            alt=""
                            style="border-radius: 0.5rem"
                        />
                        <div
                            v-else-if="
                                element.function_type === 'core' && element.height === 'a' && element.width === 'b'
                            "
                            class="core-widget"
                        >
                            <img :src="element.image" alt="" />
                        </div>
                        <img v-else :src="element.image" alt="" />
                    </div>
                </draggable>
            </div>
            <div class="bg-white rounded-4 border mx-2 dash-builder">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="background: #f1f7ff; border-radius: 14px 14px 0 0"
                >
                    <h1 class="px-3 py-3 page-heading" style="font-weight: bold; margin-bottom: 0">
                        Dashboard Builder
                    </h1>
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Change Template"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <h3
                            id="target"
                            class="px-2 py-3 page-heading cursor-pointer"
                            style="font-size: 14px; margin-bottom: 0"
                            @click="openConfirmModal = true"
                        >
                            Change Template
                        </h3>
                    </ejs-tooltip>
                </div>
                <div class="droppable-template" v-if="templateStyles">
                    <div :style="templateStyles.primeContainer">
                        <div :style="templateStyles.bgImageContainer">
                            <img
                                :style="templateStyles.bgImage"
                                :src="templateStyles.bgImageUrl"
                                width="100"
                                height="100"
                            />
                        </div>
                        <div class="t-m-c" :style="templateStyles.templateMainContainer">
                            <div class="u-l-c" :style="templateStyles.upperLayoutContainer">
                                <div
                                    v-for="(item, index) in Object.entries(template.LI)"
                                    :class="
                                        item[1].width == width && item[1].height == height
                                            ? ' highlighted-section ' + item[1].class
                                            : '' + item[1].class
                                    "
                                    :style="templateStyles.upperLayoutContainerItemCommon + item[1].style"
                                    :key="item[1].name + ''"
                                >
                                    <div
                                        class="d-flex justify-content-between w-100 align-items-center px-2 py-2"
                                        v-if="index == 0"
                                    >
                                        <div
                                            style="font-size: 20px; font-weight: bold; padding-left: 1rem"
                                            class="d-flex w-33"
                                        >
                                            Mobex Health Hub
                                        </div>
                                        <div class="d-grid w-33">
                                            <h1 style="font-weight: bold">11:59 AM</h1>
                                            <h3 style="font-weight: bold">Wednesday, February 28, 2023</h3>
                                        </div>
                                        <div class="d-flex w-33 d-flex justify-content-end" style="padding-right: 1rem">
                                            <img
                                                class="px-2"
                                                src="@/assets/px/drag-and-drop/message.png"
                                                alt=""
                                                style="width: 40px; height: 40px"
                                            />
                                            <img
                                                src="@/assets/px/drag-and-drop/logout.png"
                                                alt=""
                                                style="width: 40px; height: 40px"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        v-if="index == 1"
                                        class="d-flex w-100 justify-content-between align-items-center"
                                    >
                                        <img src="@/assets/px/drag-and-drop/widgets/welcome.png" alt="" />
                                    </div>
                                    <draggable
                                        class="draggable-list"
                                        :list="template['LI'][item[0]].elements"
                                        v-if="index > 1"
                                        :group="
                                            template['LI'][item[0]].group
                                                ? template['LI'][item[0]].group
                                                : { name: 'myGroup', pull: false, put: true }
                                        "
                                        @change="manipulateWidgets($event, 'LI', item[1].name, 'add')"
                                        :options="{ accept: false }"
                                    >
                                        <div
                                            :class="value.class"
                                            v-for="value in template['LI'][item[0]].elements"
                                            :key="value.name"
                                            style="position: relative"
                                            @mouseover="hoveredElementId = 'LI' + index"
                                            @mouseleave="hoveredElementId = null"
                                        >
                                            <img
                                                :src="value.image"
                                                alt=""
                                                class="w-100 h-100"
                                                style="border-radius: 0.5rem"
                                            />
                                            <template v-if="hoveredElementId == 'LI' + index">
                                                <div class="action-buttons-mhh w-100 h-100">
                                                    <span
                                                        class="background"
                                                        @click="
                                                            manipulateWidgets(
                                                                $event,
                                                                'LI',
                                                                item[1].name,
                                                                'remove',
                                                                value
                                                            )
                                                        "
                                                        id="delete"
                                                    >
                                                        <img
                                                            src="@/assets/px/delete-icon.png"
                                                            alt=""
                                                            style="height: 15px"
                                                        />
                                                    </span>
                                                    <b-tooltip target="delete" triggers="hover" placement="bottom"
                                                        >Delete</b-tooltip
                                                    >
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div class="g-s-c" :style="templateStyles.gridSliderContainer">
                                <div
                                    v-for="(item, index) in Object.entries(template.GSI)"
                                    :class="
                                        item[1].width == width && item[1].height == height && !item[1].elements.length
                                            ? ' highlighted-section ' + item[1].class
                                            : '' + item[1].class
                                    "
                                    :key="item[1].name + ''"
                                    :style="templateStyles.gridSliderContainerItemCommon + item[1].style"
                                >
                                    <draggable
                                        class="draggable-list"
                                        :list="template['GSI'][item[0]].elements"
                                        :group="
                                            template['GSI'][item[0]].elements.length
                                                ? { name: 'myGroup', pull: true, put: false }
                                                : { name: 'myGroup', pull: true, put: true }
                                        "
                                        @start="onMoveStart"
                                        @end="onMoveEnd"
                                        @change="manipulateWidgets($event, 'GSI', item[1].name, 'add', item, index)"
                                    >
                                        <div
                                            :class="value.class"
                                            v-for="value in template['GSI'][item[0]].elements"
                                            :key="value.name"
                                            style="
                                                position: relative;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                flex-direction: column;
                                                height: 100%;
                                            "
                                            @mouseover="hoveredElementId = 'GSI' + index"
                                            @mouseleave="hoveredElementId = null"
                                            @click="
                                                value.function_type === 'generic'
                                                    ? addDetails(value.function_name, index)
                                                    : ''
                                            "
                                        >
                                            <img
                                                v-if="value.function_type == 'generic'"
                                                :src="value.image"
                                                alt=""
                                                :class="template['GSI'][item[0]].title ? 'w-50 h-50' : 'w-90'"
                                                style="border-radius: 0.5rem"
                                            />
                                            <img
                                                v-else-if="value.function_type == '3rdParty'"
                                                :src="value.image"
                                                alt=""
                                                class="w-50"
                                                style="border-radius: 0.5rem"
                                            />
                                            <img
                                                v-else-if="
                                                    value.function_type === 'core' && value.function_name === 'RSS FEED'
                                                "
                                                :src="value.image"
                                                alt=""
                                                class="w-50"
                                                style="border-radius: 0.5rem"
                                            />
                                            <img
                                                v-else-if="value.function_type === 'core'"
                                                :src="value.image"
                                                alt=""
                                                :style="{
                                                    'max-width': value.function_name == 'Photos' ? '45%' : '70%',
                                                }"
                                            />
                                            <img
                                                v-else
                                                :src="value.image"
                                                alt=""
                                                class="h-100 w-100"
                                                style="border-radius: 0.5rem"
                                            />
                                            <p
                                                v-if="
                                                    value.function_type == 'generic' && template['GSI'][item[0]].title
                                                "
                                                class="widget-title"
                                            >
                                                {{ template['GSI'][item[0]].title }}
                                            </p>
                                            <p
                                                v-else-if="
                                                    value.function_type === 'core' && value.function_name === 'RSS FEED'
                                                "
                                                class="widget-title"
                                            >
                                                {{ value.function_name }}
                                            </p>
                                            <p v-else-if="value.function_type == '3rdParty'" class="widget-title">
                                                {{ value.function_name }}
                                            </p>
                                            <template v-if="hoveredElementId == 'GSI' + index">
                                                <div class="action-buttons-mhh w-100 h-100">
                                                    <span
                                                        class="background"
                                                        @click="
                                                            manipulateWidgets(
                                                                $event,
                                                                'GSI',
                                                                item[1].name,
                                                                'remove',
                                                                value,
                                                                index
                                                            )
                                                        "
                                                        id="delete"
                                                    >
                                                        <img
                                                            src="@/assets/px/delete-icon.png"
                                                            alt=""
                                                            style="height: 15px"
                                                        />
                                                    </span>
                                                    <!-- <b-tooltip target="delete" triggers="hover" placement="bottom">Delete</b-tooltip> -->
                                                    <div v-if="value.function_type == 'generic'">
                                                        <span
                                                            class="background"
                                                            @click="OpenEditModal(value.function_name, index)"
                                                            id="edit"
                                                        >
                                                            <img
                                                                src="@/assets/px/edit-icon.png"
                                                                alt=""
                                                                style="height: 15px"
                                                            />
                                                        </span>
                                                        <!-- <b-tooltip target="edit" triggers="hover" placement="bottom">Edit</b-tooltip> -->
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div :style="templateStyles.footerContainer" class="">
                                <div :style="templateStyles.footerWeatherInfo" class="">
                                    <div class="sun-icon"></div>
                                    <div class="weather-text">Sunny Miami Florida</div>
                                </div>
                                <div class="" :style="templateStyles.footerTabletStats">
                                    <div class="bottom-tablet-stats-icons">Bluetooth</div>
                                    <div class="bottom-tablet-stats-icons">Wifi</div>
                                    <div class="bottom-tablet-stats-icons">Online</div>
                                    <div class="bottom-tablet-stats-icons">Battery</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-delete-modal">
                <AlertChangeTemplate
                    v-if="openConfirmModal"
                    :open="openConfirmModal"
                    @cancel="openConfirmModal = false"
                    @handleChangeTemplate="handleChangeTemplate"
                >
                </AlertChangeTemplate>
            </div>
            <div class="builddashboard">
                <DashboardBuilderModal
                    v-if="openLauncherModal"
                    :open="openLauncherModal"
                    @close="close"
                    :selected="selectedOption"
                    @selection-changed="updateSelectedOption"
                    :pageData="pageData"
                />
            </div>
            <div class="addmoduledetail">
                <AddDetailModal
                    v-if="openEditModal"
                    :open="openEditModal"
                    @close="closedetail"
                    :clickedProfileFunction="clickedProfileFunction"
                    :position="position"
                    :pageData="pageData"
                    :applicationFrame="applicationFrame"
                    :current_template="current_template"
                />
            </div>
            <EducationFeedEditModal
                v-if="isEducation"
                :dashboardId="dashboardId"
                :position="position"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
                :clickedProfileFunction="clickedProfileFunction"
                @cancel="isEducation = false"
            />
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import { dashboardBuilder, memberProfile } from '@/util/apiRequests';
    import draggable from 'vuedraggable';
    import DashboardBuilderModal from '@/components/dashboard/DashboardBuilderModal.vue';
    import AlertChangeTemplate from './ChangeTemplateAlert.vue';
    import AddDetailModal from '../../components/company/member-profiles/AddGenericFunctionDetail.vue';
    import EducationFeedEditModal from './education_feed_edit_modal.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { deleteAllEducationResourcesByDashboardId } from '@/util/dashboard';

    export default {
        name: 'BuildDashboard',
        components: {
            draggable,
            DashboardBuilderModal,
            AlertChangeTemplate,
            AddDetailModal,
            EducationFeedEditModal,
            Loading,
        },
        props: {
            pageData: {
                type: Object,
            },
            current_template: {
                type: Number,
            },
            applicationFrame: {
                type: String,
            },
            dashboardId: {
                type: Number,
            },
        },
        data() {
            return {
                selectedOption: this.current_template,
                templateStyles: null,
                template: null,
                widgetsList: [],
                isHighlighted: false,
                inp: this.value,
                ref_span: null,
                openLauncherModal: false,
                openEditModal: false,
                openConfirmModal: false,
                templateData: {},
                templateDataList: [],
                height: '',
                width: '',
                showButtonsLI: [],
                showButtonsGSI: [],
                hoveredElementId: null,
                allWidgets: [],
                clickedProfileFunction: '',
                position: '',
                elementDragStartId: '',
                loading: true,
                isItemMoved: false,
                movedWidget: null,
                movedWidgetIndex: null,
                isEducation: false,
            };
        },
        methods: {
            showButton(index, label) {
                if (label == 'LI') {
                    this.$set(this.showButtonsLI, index, true);
                } else {
                    this.$set(this.showButtonsGSI, index, true);
                }
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            hideButton(index, label) {
                if (label == 'LI') {
                    this.$set(this.showButtonsLI, index, false);
                } else {
                    this.$set(this.showButtonsGSI, index, false);
                }
            },
            addDetails(val, index) {
                let i = index + 1;
                this.position = i + '';
                this.clickedProfileFunction = val;
            },
            OpenEditModal(val, index) {
                let i = index + 1;
                this.position = i + '';
                this.clickedProfileFunction = val;
                if (val === 'Education') {
                    this.isEducation = true;
                } else {
                    this.openEditModal = true;
                }
            },
            closedetail() {
                this.openEditModal = false;
            },
            async deleteApplication(element, index) {
                try {
                    let i = index + 1;
                    this.position = i + '';
                    this.clickedProfileFunction = element.function_name;

                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.position,
                        this.dashboardId
                    );

                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    const info = functionResponse.data;

                    if (info.info.length) {
                        this.loading = true;
                        const endpoint = memberProfile.deleteGenericModule(
                            info.info[0].id,
                            this.clickedProfileFunction,
                            info.info[0].application,
                            this.pageData?.page2?.isMaster,
                            this.dashboardId
                        );
                        const saveFunctionResponse = await this.$api.delete(endpoint);
                        if (!saveFunctionResponse.data.success) {
                            throw saveFunctionResponse.data.error;
                        }
                        this.$toasted.success('Application deleted successfully !');
                    } else {
                        await deleteAllEducationResourcesByDashboardId(this.applicationFrame, this.dashboardId);
                    }
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to delete application !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.loading = false;
                }
            },
            handleChangeTemplate() {
                this.openLauncherModal = true;
                this.openConfirmModal = false;
            },
            async getTemplateMetaData() {
                let metaData = await this.$api.get(dashboardBuilder.getTemplateMeta(this.selectedOption));
                let templateMeta = JSON.parse(metaData.data.templateMeta);
                let _template = templateMeta.templateItems;
                const GSIElement = _template['GSI']['GSI 1'];
                _template['GSI'] = {};
                const GSIs = this.allWidgets.filter((el) => el.height == 'a' && el.width == 'b');
                let _GSIElement = {};
                for (const i in GSIs) {
                    _GSIElement['GSI ' + (i + 1)] = {
                        ...GSIElement,
                        name: 'GSI ' + (i + 1),
                    };
                }
                _template['GSI'] = JSON.parse(JSON.stringify(_GSIElement));
                this.template = _template;
                Object.keys(this.template).forEach((group) => {
                    let groupElements = Object.keys(this.template[group]);
                    this.templateData[group] = {};
                    groupElements.forEach((groupEl) => {
                        this.templateData[group][groupEl] = undefined;
                    });
                });
                this.templateStyles = templateMeta.templateCommonStyles;
                this.$emit('captureValues', {
                    page4: { template: this.template, templateStyles: this.templateStyles },
                });
                return true;
            },
            manipulateWidgets(event, section, listName, operation, element, index) {
                if (operation === 'remove') {
                    if (section == 'GSI') {
                        // this.template[section][listName].style = `background-color: rgba(255, 255, 255, 0.85);`
                        // this.template[section][listName].style += `color: black;`
                        this.setColorFromColorScheme(listName);
                        this.template['GSI'][listName].isSensitive = 0;
                    }
                    if (element.function_type != 'generic') {
                        if (element.function_type == 'core') {
                            let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                            if (idx != -1) {
                                this.widgetsList.splice(idx, 0, {
                                    ...element,
                                });
                            } else {
                                this.widgetsList.push(element);
                            }
                        } else {
                            this.widgetsList.push(element);
                        }

                        element.class = 'list-item removed';
                        this.template[section][listName].title = '';
                        this.template[section][listName].elements.pop();
                        this.templateData[section][listName] = undefined;
                        this.$set(this.template[section][listName], 'group', undefined);
                    } else {
                        if (['Mood Tracker', 'Education'].includes(element.function_name)) {
                            let idx = this.widgetsList.findIndex((item) => item.function_type == 'core');
                            if (idx != -1) {
                                this.widgetsList.splice(idx, 0, {
                                    ...element,
                                });
                            } else {
                                this.widgetsList.push(element);
                            }

                            element.class = 'list-item removed';
                            this.template[section][listName].title = '';
                            this.template[section][listName].elements.pop();
                            this.templateData[section][listName] = undefined;
                            this.$set(this.template[section][listName], 'group', undefined);
                        }

                        this.deleteApplication(element, index);

                        if (this.widgetsList.length >= 1) {
                            element.class = 'list-item removed';

                            this.template[section][listName].elements.pop();
                            this.template[section][listName].title = '';
                            this.templateData[section][listName] = undefined;
                            this.$set(this.template[section][listName], 'group', undefined);
                            return;
                        }
                    }
                } else {
                    if (event.removed != undefined) {
                        if (isNaN(this.movedWidgetIndex)) {
                            this.template[section][listName].elements.push(event.removed.element);
                            this.templateData[section][listName] = event.removed.element;
                            return;
                        }
                        this.moveWidget(event, section, listName, index);
                        return;
                    }

                    if (this.isItemMoved) {
                        this.movedWidget = listName;
                        this.movedWidgetIndex = index + 1;
                    }

                    let newlyAdded = operation === 'add' ? event.added.element : null;
                    if (!newlyAdded.function_type) {
                        delete newlyAdded.company_id;
                        delete newlyAdded.created_at;
                        delete newlyAdded.updated_at;
                    }
                    newlyAdded['function_type'] = !newlyAdded.function_type ? 'core' : newlyAdded.function_type;
                    if (operation === 'add') {
                        if (this.template[section][listName].elements.length > 1) {
                            this.template[section][listName].elements.splice(1);
                            newlyAdded.class = 'list-item removed';
                            if (newlyAdded.function_type == 'generic') {
                                this.widgetsList.unshift(newlyAdded);
                            } else if (newlyAdded.function_type == 'core') {
                                let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                                this.widgetsList.splice(idx, 0, {
                                    ...newlyAdded,
                                });
                            } else {
                                this.widgetsList.push(newlyAdded);
                            }
                        } else if (
                            newlyAdded.width != this.template[section][listName].width &&
                            newlyAdded.height != this.template[section][listName].height
                        ) {
                            this.template[section][listName].elements.splice(0);
                            newlyAdded.class = 'list-item removed';

                            if (!this.isItemMoved) {
                                if (newlyAdded.function_type == 'generic') {
                                    this.widgetsList.unshift(newlyAdded);
                                } else if (newlyAdded.function_type == 'core') {
                                    let idx = this.widgetsList.findIndex((item) => item.function_type == '3rdParty');
                                    this.widgetsList.splice(idx, 0, {
                                        ...newlyAdded,
                                    });
                                } else {
                                    this.widgetsList.push(newlyAdded);
                                }
                            }
                        } else {
                            if (event.added.element.function_type == 'generic' && !this.isItemMoved) {
                                if (!['Mood Tracker', 'Education'].includes(event.added.element.function_name)) {
                                    this.widgetsList.splice(this.elementDragStartId, 0, {
                                        ...event.added.element,
                                        id: event.added.element.id + 1,
                                    });
                                }
                            }

                            this.$set(this.template[section][listName], 'group', {
                                name: 'myGroup',
                                pull: false,
                                put: false,
                            });
                            this.templateData[section][listName] = newlyAdded;
                        }
                    }
                }
                this.$emit('captureValues', { page4: { templateData: this.templateData } });
            },
            async updateSelectedOption(option) {
                if (this.selectedOption != option) {
                    this.selectedOption = option;
                    this.$emit('selection-changed', this.selectedOption);
                    await this.getTemplateMetaData();
                    await this.getWidgets();
                    this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
                }
            },
            onDragStart(event) {
                this.elementDragStartId = event.item.id;
                let element = event.item._underlying_vm_;
                this.height = element.height;
                this.width = element.width;
            },
            onDragEnd() {
                this.height = null;
                this.width = null;
            },
            onMoveStart(event) {
                this.isItemMoved = true;
                this.onDragStart(event);
            },
            onMoveEnd() {
                this.isItemMoved = false;
                this.onDragEnd();
            },
            async moveWidget(event, section, listName, removedPosition) {
                try {
                    if (event?.removed?.element?.function_type != 'generic') {
                        this.template[section][listName].elements.pop();
                        this.template[section][listName].title = '';
                        this.templateData[section][listName] = undefined;
                        this.$set(this.template[section][listName], 'group', undefined);
                        return;
                    }

                    const endpoint1 = await memberProfile.getSpecificGenericFunction(
                        event.removed.element.function_name,
                        removedPosition + 1,
                        this.dashboardId
                    );

                    const functionResponse = await this.$api.get(endpoint1);

                    if (functionResponse?.data?.info?.length) {
                        let moduleId = functionResponse?.data?.info[0].id;
                        const endpoint = memberProfile.updateGenericModule(moduleId);

                        await this.$api.put(endpoint, {
                            application: this.applicationFrame,
                            generic_function: event.removed.element.function_name,
                            position: this.movedWidgetIndex + '',
                        });

                        this.template[section][this.movedWidget].title = this.template[section][listName].title;
                        this.template[section][listName].elements.pop();
                        this.template[section][listName].title = '';

                        let bgcolor = this.template[section][listName].style.match(/background-color:[^;]*/)[0];
                        this.template[section][this.movedWidget].style = this.template[section][
                            this.movedWidget
                        ].style.replace(/background-color:[^;]*/, bgcolor);

                        let styleString = this.template[section][listName].style.split(';');
                        let color;

                        styleString.forEach((item) => {
                            const propertyValue = item.split(':');
                            if (propertyValue[0].trim() === 'color') {
                                color = `color:${propertyValue[1].trim()}`;
                                return;
                            }
                        });

                        styleString = this.template[section][this.movedWidget].style.split(';');
                        styleString.forEach((item, index, dummy) => {
                            const propertyValue = item.split(':');
                            if (propertyValue[0].trim() === 'color') {
                                dummy[index] = color;
                                return;
                            }
                        });
                        this.template[section][this.movedWidget].style = styleString.join(';');

                        this.template[section][listName].style = this.template[section][listName].style.replace(
                            /background-color:[^;]*/,
                            `background-color:${this.pageData.page3.colorScheme.colors[0][0]}`
                        );
                        const styleArray = this.template[section][listName].style.split(';');
                        styleArray.forEach((item, index, dummy) => {
                            const propertyValue = item.split(':');
                            if (propertyValue[0].trim() === 'color') {
                                dummy[index] = `color:${this.pageData.page3.colorScheme.colors[0][1]}`;
                                return;
                            }
                        });

                        this.template[section][listName].style = styleArray.join(';');
                        this.templateData[section][listName] = undefined;
                        this.$set(this.template[section][listName], 'group', undefined);
                    }
                } catch (err) {
                    this.$toasted.error('Failed to update Application');
                }
            },
            close() {
                this.openLauncherModal = false;
            },
            async getGenericWidgets() {
                const requestData = {
                    functionNames: this.pageData.page2.genericFunctions.map((el) => el.function_name),
                    application: this.applicationFrame,
                };
                const endpoint = dashboardBuilder.getWidgets();
                let response = await this.$api.post(endpoint, requestData);
                let res = response.data.data.map((el) => {
                    let data = {
                        ...el,
                        function_type: 'generic',
                    };
                    return data;
                });
                return res;
            },
            async getWidgets() {
                const response = await Promise.all([
                    this.getGenericWidgets(),
                    this.getCoreWidgets(),
                    this.get3rdPartyFunctionWidgets(),
                ]);
                this.widgetsList = this.widgetsList.concat(response[0]);
                this.widgetsList = this.widgetsList.concat(response[1]);
                this.widgetsList = this.widgetsList.concat(response[2]);
            },
            async getCoreWidgets() {
                const requestData = {
                    functionNames: this.pageData.page2.coreFunctions.map((el) => el.function_name),
                    application: this.applicationFrame,
                };
                const endpoint = dashboardBuilder.getWidgets();
                let response = await this.$api.post(endpoint, requestData);
                let res = response.data.data.map((el) => {
                    let data = {
                        ...el,
                        function_type: 'core',
                    };
                    return data;
                });
                return res;
            },
            async get3rdPartyFunctionWidgets() {
                if (this.pageData.page2.thirdPartyFunctions) {
                    const requestData = {
                        functionIds: this.pageData.page2.thirdPartyFunctions.map((el) => el.id),
                    };
                    const endpoint = memberProfile.getFunctionDetailsByIds();
                    let response = await this.$api.post(endpoint, requestData);
                    let thirdPartyWidgets = response.data.data.map((el) => {
                        let data = {
                            ...el,
                            height: 'a',
                            width: 'b',
                        };
                        return data;
                    });
                    return thirdPartyWidgets;
                } else {
                    return [];
                }
            },
            setColorFromColorScheme(item) {
                const { colorScheme } = this.pageData.page3;

                this.template['GSI'][item].style = `background-color: ${colorScheme.colors[0][0]};`;
                this.template['GSI'][item].style += `color: ${colorScheme.colors[0][1]}`;
            },
        },
        watch: {},

        async created() {
            window.scrollTo(0, 0);

            await this.getWidgets();
            const widgets = this.widgetsList.map((el) => el.id);
            this.allWidgets = this.widgetsList;
            const widgetsToRemoveIndexs = [];
            const widgetsToRemoveFunctionName = [];

            if (
                this.pageData.page4 &&
                this.pageData.page4.template &&
                this.pageData.page4.templateStyles &&
                this.pageData.page4.templateData
            ) {
                const { template, templateData, templateStyles } = this.pageData.page4;
                if (template) {
                    this.template = template;
                }
                if (templateStyles) {
                    this.templateStyles = templateStyles;
                }
                if (templateData) {
                    this.templateData = templateData;
                }

                this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
                Object.keys(this.template).forEach((parentEl) => {
                    if (!this.template[parentEl]) {
                        this.templateData[parentEl] = {};
                    }
                    Object.keys(this.template[parentEl]).forEach((childEl) => {
                        if (!this.templateData[parentEl][childEl]) {
                            this.templateData[parentEl][childEl] = {};
                        }
                        if (this.templateData[parentEl][childEl].function_type != 'generic') {
                            if (this.templateData[parentEl][childEl]) {
                                let i = widgets.indexOf(this.templateData[parentEl][childEl].id);
                                if (i > -1) {
                                    this.templateData[parentEl][childEl].image = this.widgetsList[i].image;
                                    this.$set(this.template[parentEl][childEl], 'elements', [
                                        this.templateData[parentEl][childEl],
                                    ]);
                                    widgetsToRemoveIndexs.push(this.templateData[parentEl][childEl].id);
                                    this.$set(this.template[parentEl][childEl], 'group', {
                                        name: 'myGroup',
                                        pull: false,
                                        put: false,
                                    });
                                } else {
                                    this.$set(this.template[parentEl][childEl], 'elements', []);
                                    this.templateData[parentEl][childEl] = undefined;
                                    this.$set(this.template[parentEl][childEl], 'group', {
                                        name: 'myGroup',
                                        pull: false,
                                        put: true,
                                    });
                                }
                            } else {
                                this.$set(this.template[parentEl][childEl], 'elements', []);
                                this.$set(this.template[parentEl][childEl], 'group', {
                                    name: 'myGroup',
                                    pull: false,
                                    put: true,
                                });
                            }
                        }
                        if (
                            ['Mood Tracker', 'Education'].includes(this.templateData[parentEl][childEl]?.function_name)
                        ) {
                            if (this.templateData[parentEl][childEl]) {
                                widgetsToRemoveFunctionName.push(this.templateData[parentEl][childEl]?.function_name);
                            }
                        }
                    });
                });

                widgetsToRemoveIndexs.forEach((id) => {
                    const i = this.widgetsList.findIndex((el) => el.id == id);
                    this.widgetsList.splice(i, 1);
                });

                widgetsToRemoveFunctionName.forEach((name) => {
                    const i = this.widgetsList.findIndex((el) => el.function_name == name);
                    this.widgetsList.splice(i, 1);
                });

                Object.keys(this.template).forEach((group) => {
                    Object.keys(this.template[group]).forEach(async (item) => {
                        if (this.template[group][item].elements.length) {
                            let elements = this.template[group][item].elements[0];
                            if (elements.image_id) {
                                elements.image = await this.geturl(elements.image_id);
                            } else if (elements.widget_png) {
                                elements.image = await this.geturl(elements.widget_png);
                            } else if (elements.fileId) {
                                elements.image = await this.geturl(elements.fileId);
                            }
                        }
                    });
                });
                // This is removed to handle custom colors for modules
                // Object.keys(this.template).forEach((group) => {
                //     if (group === 'GSI') {
                //         Object.keys(this.template[group]).forEach((gsi) => {
                //             const gsiStyle = this.template[group][gsi].style;
                //             const { colorScheme } = this.pageData.page3;

                //             if (gsiStyle.includes('background-color')) {
                //                 this.template[group][gsi].style = gsiStyle.replace(
                //                     /background-color:[^;]*/,
                //                     `background-color:${this.pageData.page3.colorScheme.colors[0][0]}`
                //                 );
                //             } else {
                //                 this.template[group][
                //                     gsi
                //                 ].style += `background-color:${this.pageData.page3.colorScheme.colors[0][0]};`;
                //             }

                //             const styleArray = this.template[group][gsi].style.split(';');
                //             let colorText = 0;

                //             styleArray.forEach((style, index, array) => {
                //                 const propertyValue = style.split(':');
                //                 if (propertyValue[0].trim() === 'color') {
                //                     colorText++;
                //                     array[index] = `color:${colorScheme.colors[0][1]}`;
                //                 }
                //             });

                //             // Join the modified style array back into a string
                //             this.template[group][gsi].style = styleArray.join(';');

                //             if (!colorText) {
                //                 this.template[group][gsi].style += `color:${colorScheme.colors[0][1]};`;
                //                 colorText = 0;
                //             }

                //             const elements = this.template[group][gsi].elements[0];

                //             if (elements != undefined && elements.function_type == 'generic') {
                //                 this.template[group][gsi].isSensitive = this.template[group][gsi]?.isSensitive ?? 0;
                //             }
                //         });
                //     }
                // });
            } else {
                await this.getTemplateMetaData();
                this.templateStyles.bgImageUrl = this.pageData.page3.background.image;
                Object.keys(this.template).forEach((group) => {
                    if (group === 'GSI') {
                        Object.keys(this.template[group]).forEach((gsi) => {
                            this.setColorFromColorScheme(gsi);

                            const elements = this.template[group][gsi].elements[0];

                            if (elements != undefined && elements.function_type == 'generic') {
                                this.template[group][gsi].isSensitive = this.template[group][gsi]?.isSensitive ?? 0;
                            }
                        });
                    }
                });
            }

            this.loading = false;
        },
    };
</script>
<style scoped>
    .hover-button {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, -180%);
    }
    .dashboard-builder-template {
        background: rgba(255, 255, 255, 0.9);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        max-width: 1500px;
        gap: 2rem;
        padding: 2rem;
    }
    .dashboard-builder-template > div {
        border-radius: 1rem !important;
        box-sizing: border-box;
    }
    .widget-list {
        width: 25%;
        min-width: 18rem;
        border: 1px solid rgb(193, 187, 187);
    }
    .widget-list-header {
        /* padding: 2rem; */
        background-color: rgb(214, 230, 235);
        font-weight: 700;
        border-radius: 1rem 1rem 0 0;
        padding: 1rem;
        /* display: none!important; */
    }
    .generic-widget {
        background-color: #fff;
        box-shadow: 0 4px 15px 5px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        height: 8rem;
        width: 8rem;
        display: flex;
        align-items: center;
    }
    .generic-widget > img {
        height: 70%;
    }
    .core-widget {
        width: calc(var(--mini-display-width) / 6.3);
        box-shadow: 0px 1px 6px rgba(58, 64, 82, 0.15);
        background: rgba(81, 85, 195, 0.95);
        padding: 10px;
        border-radius: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
        min-width: 7rem;
    }

    .core-widget > img {
        height: 70%;
    }

    .widgets-list .widget-item {
        margin: 10px;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        border-radius: 5px;
        background: #27a2d3;
        display: inline-block;
    }
    .draggable-list {
        height: 100%;
        width: 100%;
    }
    .highlighted-section {
        border: 4px dotted #1fcf25;
    }
    .list-item {
        height: 100%;
        width: 100%;
        /* background: lightblue; */
    }
    .action-buttons-mhh {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        top: 0;
        background-color: rgb(89, 90, 194, 0.85);
        border-radius: 0.5rem;
    }
    .background {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e8f0fd;
        border: 1px solid black;
    }
    .widget-title {
        height: 20px;
        width: 80%;
        margin-top: 0.5rem;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        text-transform: capitalize;
    }
</style>
